import React, { useState } from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

import styled from "styled-components"
import cafeImage from "../assets/images/01_HOME_button_images/cafe_1.jpg"
import coworkImage from "../assets/images/01_HOME_button_images/cowork_7.jpg"
import roomsImage from "../assets/images/01_HOME_button_images/rooms_4.jpg"
import studioImage from "../assets/images/01_HOME_button_images/studio_1.jpg"
import shopImage from "../assets/images/01_HOME_button_images/shop_1.jpg"
import SEO from "../components/SEO"

const HomePageStyles = styled.div`
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: calc(100vh - 150px);
  display: grid;
  grid-row-gap: 20px;
  @media (max-width: 900px) {
    height: 100%;
  }
`

const SectionStyles = styled.section`
  height: calc(100vh - 150px);
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  color: var(--white);
  font-size: clamp(20px, 2.5vw, 30px);
  text-transform: uppercase;
  scroll-snap-align: start;

  h2 {
    margin: 70px;

    @media (max-width: 900px) {
      margin: 40px;
    }
  }
  @media (max-width: 900px) {
    height: 50vh;
  }
`

const IndexPage = () => {
  const [sections] = useState([
    { url: cafeImage, title: "cafe", align: "left" },
    { url: coworkImage, title: "cowork", align: "left" },
    { url: roomsImage, title: "rooms", align: "center" },
    { url: studioImage, title: "studio", align: "center" },
    { url: shopImage, title: "shop", align: "left" },
  ])
  return (
    <>
      <SEO
        title="Verse Collective | cafe • cowork • studio • hostel • shop • rooms"
        image={cafeImage}
        description={
          "A co-work space on our favourite beach on the island, dikwella. Allowing traveling creatives to balance work & island life. "
        }
      />
      <Fade>
        <HomePageStyles>
          {sections.map((section, index) => {
            return (
              <Link to={`/${section.title}`}>
                <SectionStyles
                  key={index}
                  style={{
                    background: `url(${section.url}) no-repeat ${section.align} center`,
                    backgroundSize: "cover",
                  }}
                >
                  <h2>{section.title}</h2>
                </SectionStyles>
              </Link>
            )
          })}
        </HomePageStyles>
      </Fade>
    </>
  )
}

export default IndexPage
